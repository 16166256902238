let _printResultIntervalFn;
let _currentLetterIndex = 0;
let _response = '';
let _inProgress = false;
let _isFinished = false;

onmessage = event => {
  const { response } = event.data;
  const { finished } = event.data;
  const { abort } = event.data;

  _isFinished = finished;
  _response = response;

  if (abort) {
    clearInterval(_printResultIntervalFn);
    _currentLetterIndex = 0;
    _response = '';
    _inProgress = false;
    _isFinished = false;
    return;
  }

  if (!_inProgress) {
    _printResultIntervalFn = setInterval(() => {
      _inProgress = true;
      if (_response == null || _response === undefined || _response === '')
        return;

      if (_currentLetterIndex > _response.length - 1 && _isFinished) {
        clearInterval(_printResultIntervalFn);

        _currentLetterIndex = 0;
        _response = '';
        _inProgress = false;
        _isFinished = false;
      }

      if (_currentLetterIndex <= _response.length - 1) {
        postMessage({
          message: _response[_currentLetterIndex],
          complete: _currentLetterIndex === _response.length - 1,
          index: _currentLetterIndex,
        });

        _currentLetterIndex += 1;
      }
    }, 10);
  }
};
